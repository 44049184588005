import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  propList: [
    {
      prop: 'id',
      label: 'ID',
      width: '100'
    },
    {
      prop: 'name',
      label: 'param.param-name'
    },
    {
      prop: 'defaultValue',
      label: 'param.default-value'
    },
    {
      prop: 'paramType',
      label: 'param.param-value-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'value_type')
      }
    },
    {
      prop: 'description',
      label: 'general.description',
      width: '320'
    },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'status')
      },
      width: '100'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      prop: 'options',
      label: 'param.param-operation',
      width: '380',
      slotName: 'handler'
    }
  ],
  // showIndexColumn: true,
  showSelectColumn: true
}
